<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text=""></loading>
    <el-form ref="form" :model="paper"  label-width="100px">
      <el-form-item label="分享主题" :required="true">
        <el-input type="text"  v-model="paper.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="分享描述" :required="true">
        <el-input type="text" v-model="paper.des" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="试题来源" prop="sourceId">
        <el-radio v-model="paper.questionTypeId" :label="1">客观题</el-radio>
        <el-radio v-model="paper.questionTypeId" :label="2">主观题</el-radio>
      </el-form-item>
      <div id="divKG" v-show="paper.questionTypeId == 1">
          <el-form-item label="单选题">
            <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('danXuan')">选择题目 >></div>
            <div id="divDanXuanQuestions" class="layui-input-block question-list">
              <p v-for="(question,index) in this.danXuanQuestions">
                <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
                <span style='color: #666'> {{question.stem}}</span>
                <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeDanXuanQuestion(question.id);">[删除]</span>
              </p>
            </div>
          </el-form-item>
          <el-form-item label="多选题">
            <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('duoXuan')">选择题目 >></div>
            <div id="divDuoXuanQuestions" class="layui-input-block question-list">
              <p v-for="(question,index) in this.duoXuanQuestions">
                <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
                <span style='color: #666'> {{question.stem}}</span>
                <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeDuoXuanQuestion(question.id);">[删除]</span>
              </p>
            </div>
          </el-form-item>
          <el-form-item label="不定项">
            <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('bdx')">选择题目 >></div>
            <div  id="divBdxQuestions" class="layui-input-block question-list" style="display: block">
              <p v-for="(question,index) in this.bdxQuestions">
                <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
                <span style='color: #666'> {{question.stem}}</span>
                <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeBdxQuestion(question.id);">[删除]</span>
              </p>
            </div>
          </el-form-item>
      </div>
      <div id="divZG" v-show="paper.questionTypeId == 2">
        <el-form-item label="主观题">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('zg')">选择题目 >></div>
          <div  id="divClQuestions" class="layui-input-block question-list">
            <p v-for="(question,index) in this.clQuestions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeClQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
      </div>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  QuestionTypeEnum,
  RoundEnum,
  TipEnum,
  UrlEnum,
  ModuleEnum,
    PaperMakeWayEnum,
  getLocalProjectId,
  request
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";
export default {
  name: "SharePaperSave",
  components: {Loading},
  data() {
    return {
      loading: false,
      danXuanQuestions:[],
      duoXuanQuestions:[],
      bdxQuestions:[],
      clQuestions:[],
      id:0,
      paper:{
        name:'',
        des:'',
        sourceId:0,
        questionTypeId:1,
        roundId:0,
        projectId:getLocalProjectId(),
        moduleId: ModuleEnum.TKFX,
        makeWay:PaperMakeWayEnum.MANUAL,
        startTime: new Date().format("yyyy-MM-dd hh:mm:ss"),
        endTime: new Date().format("yyyy-MM-dd hh:mm:ss")
      }
    }
  },
  methods:{
    initData(){
      this.loading=true;
      this.$http({
        method: "get",
        url: UrlEnum.PAPER + "/" + request('id'),
      }).then((res) => {
        this.loading=false;
        this.paper = res.data;
        console.log(this.paper);
        this.getQuestions(this.paper.danxuanIds,QuestionTypeEnum.DAN_XUAN);
        this.getQuestions(this.paper.duoxuanIds,QuestionTypeEnum.DUO_XUAN);
        this.getQuestions(this.paper.bdxIds,QuestionTypeEnum.BU_DING_XIANG);
        this.getQuestions(this.paper.clIds,QuestionTypeEnum.CAILIAO);
      }).catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    handleClick(typeIds){
      let selectedQuestions = [];
      if(typeIds == 'danXuan'){
        typeIds = QuestionTypeEnum.DAN_XUAN;
        selectedQuestions = this.danXuanQuestions;
      }
      else if(typeIds == 'duoXuan'){
        typeIds = QuestionTypeEnum.DUO_XUAN;
        selectedQuestions = this.duoXuanQuestions;
      }
      else if(typeIds == 'bdx'){
        typeIds = QuestionTypeEnum.BU_DING_XIANG;
        selectedQuestions = this.bdxQuestions;
      }
      else if(typeIds == 'zg'){
        typeIds = QuestionTypeEnum.CAILIAO + "," + QuestionTypeEnum.JIAN_DA + "," + QuestionTypeEnum.LUN_SHU + "," + QuestionTypeEnum.FA_TIAO + "," + QuestionTypeEnum.AN_LI + "," + QuestionTypeEnum.FEN_XI + "," + QuestionTypeEnum.FENXI_LUNSHU;
        selectedQuestions = this.clQuestions;
      }
      this.$router.push({
        name:'SelectQuestion',
        params:{
          typeIds:typeIds,
          selectedQuestions:selectedQuestions
        }
      })
    },
    removeDanXuanQuestion(id){
      for(let i = this.danXuanQuestions.length-1; i>=0; i--){
        if(this.danXuanQuestions[i].id == id){
          this.danXuanQuestions.splice(i,1);
        }
      }
    },
    removeDuoXuanQuestion(id){
      for(let i = this.duoXuanQuestions.length-1; i>=0; i--){
        if(this.duoXuanQuestions[i].id == id){
          this.duoXuanQuestions.splice(i,1);
        }
      }
    },
    removeBdxQuestion(id){
      for(let i = this.bdxQuestions.length-1; i>=0; i--){
        if(this.bdxQuestions[i].id == id){
          this.bdxQuestions.splice(i,1);
        }
      }
    },
    removeClQuestion(id){
      for(let i = this.clQuestions.length-1; i>=0; i--){
        if(this.clQuestions[i].id == id){
          this.clQuestions.splice(i,1);
        }
      }
    },
    getQuestions(ids,questionType) {
      if(ids == ""){
        return;
      }
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION+"?page=1&size=100&ids="+ids,
      }).then((res) => {
        let questions = res.data.list;
        for(var i=0;i<questions.length;i++){
          questions[i].stem = questions[i].stem.substring(0,150);
        }

        if(questionType == QuestionTypeEnum.DAN_XUAN){
          this.danXuanQuestions = questions;
        }
        else if(questionType == QuestionTypeEnum.DUO_XUAN){
          this.duoXuanQuestions = questions;
        }
        else if(questionType == QuestionTypeEnum.BU_DING_XIANG){
          this.bdxQuestions = questions;
        }
        else if(questionType == QuestionTypeEnum.CAILIAO){
          this.clQuestions = questions;
        }
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getDanXuanIds() {
      var ids = [];
      for(let i = 0; i < this.danXuanQuestions.length; i++){
        const question = this.danXuanQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getDuoXuanIds() {
      var ids = [];
      for(var i = 0;i < this.duoXuanQuestions.length; i++){
        const question = this.duoXuanQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getBdxIds() {
      var ids = [];
      for(let i = 0; i < this.bdxQuestions.length; i++){
        const question = this.bdxQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getClIds() {
      const ids = [];
      for(var i = 0;i < this.clQuestions.length; i++){
        const question = this.clQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    submit:function (){
      if(this.paper.name == ""){
        this.showMsgError("请输入分享主题");
        return;
      }
      if(this.paper.des == ""){
        this.showMsgError("请输入分享描述");
        return;
      }
      if(this.paper.questionTypeId == QuestionTypeEnum.KE_GUAN_TI){
        this.paper.danxuanIds = this.getDanXuanIds();
        this.paper.duoxuanIds = this.getDuoXuanIds();
        this.paper.bdxIds = this.getBdxIds();
        //防止试卷类型与题目不匹配
        this.paper.clIds = [];
      }
      else{
        this.paper.clIds = this.getClIds();
        //防止试卷类型与题目不匹配
        this.paper.danxuanIds = [];
        this.paper.duoxuanIds = [];
        this.paper.bdxIds = [];
      }
      console.log(JSON.stringify(this.paper));
      this.loading = true;
      var url = UrlEnum.PAPER;
      var method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }

      this.$http({
        method: method,
        url: url,
        data: this.paper,
      })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
            setTimeout(function () {
              parent.layui.table.reload('tableData'); //重载表格
              parent.layer.close(index); //再执行关闭
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
    activated() {
      let questions = this.$route.params.selectedQuestions;
      if(questions != null && questions.length > 0){
        if(questions[0].id != null){
          if(questions[0].typeId == QuestionTypeEnum.DAN_XUAN){
            this.danXuanQuestions = questions;
            console.log(this.danXuanQuestions);
          }
          else if(questions[0].typeId == QuestionTypeEnum.DUO_XUAN){
            this.duoXuanQuestions = questions;
          }
          else if(questions[0].typeId == QuestionTypeEnum.BU_DING_XIANG){
            this.bdxQuestions = questions;
          }
          else if(questions[0].typeId == QuestionTypeEnum.CAILIAO){
            this.clQuestions = questions;
          }
        }
      }
    }
  },
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id=request('id');
      this.initData();
    }
  },
}
</script>
<style scoped>
@import "../../../../static/css/add.css";
.question-list{
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
  min-height: 36px;
}
.question-list p{
  padding:0px;
  line-height: 20px;
}
</style>
